import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import mystoryStyles from "./mystory.module.scss"
import BackButton from "../components/backbutton"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SEO from "../components/seo"

const NavButtons = (props) => {
    
    if (props.currentCardIndex === 0){
        return (
        <div className={mystoryStyles.navButtons}>
            <FontAwesomeIcon 
                className={mystoryStyles.rightButton}
                icon={faArrowRight}
                onClick={props.showNextCard}
            />
            </div>
        )
    }
    else if (props.currentCardIndex === 1){
        return (
            <div className={mystoryStyles.navButtons}>
            <FontAwesomeIcon 
                className={mystoryStyles.leftButton}
                icon={faArrowLeft} 
                onClick={props.showPreviousCard}
            /> 
            <FontAwesomeIcon 
                className={mystoryStyles.rightButton}
                icon={faArrowRight}
                onClick={props.showNextCard}
            />
            </div>
        )
    } else {
        return (
            <div className={mystoryStyles.navButtons}>
            <FontAwesomeIcon 
                className={mystoryStyles.leftButton}
                icon={faArrowLeft} 
                onClick={props.showPreviousCard}
            /> 
            </div>
        )
    }
}

class MyStory extends React.Component {
    constructor(props) {
      super();
      this.props = props;
      this.state = {currentCardIndex: 0};
    }

    showNextCard = async () => {
        if (this.state.currentCardIndex < 2){
            await this.setState({currentCardIndex: this.state.currentCardIndex + 1});
        }
    }

    showPreviousCard = async () => {
        if (this.state.currentCardIndex > 0){
            await this.setState({currentCardIndex: this.state.currentCardIndex - 1});
        }
    }

    render() {

      if (this.state.currentCardIndex === 0){
        return (
            <div className={mystoryStyles.background}>
                <Layout>
                    <SEO
                        title={ "Phil Cohn - My Story"}
                        description={'Read about the journey of freelance AR creator Phil Cohn.'}
                        image={'/static/favicon.ico'}
                    />
                    <div className={mystoryStyles.storycard}>
                        <h2>
                            Early Life
                        </h2>
                        <BackButton destination="/about/"/>
                        <NavButtons 
                            currentCardIndex={this.state.currentCardIndex}
                            showNextCard={this.showNextCard}
                            showPreviousCard={this.showPreviousCard}
                            />
                        <Img 
                            className={mystoryStyles.storyImage}
                            fluid={this.props.data.image1.childImageSharp.fluid} 
                            alt="" 
                            imgStyle={{ objectFit: 'contain' }}/>
                        <p>
                        I grew up in West Yorkshire, England, and spent most of my childhood playing with lego and pretending to be a dinosaur. When I was a teenager, I funnelled my creativity into learning the guitar, a hobby I still practice to this day. I loved nature, and so I decided to study Zoology at the University of Manchester. 
                        </p>
                    </div>
                    <div className={mystoryStyles.storycardNext}></div>
                    <div className={mystoryStyles.storycardAfterNext}></div>
                </Layout>
            </div>
          )
      } else if (this.state.currentCardIndex === 1){
        return (
            <div className={mystoryStyles.background}>
                <Layout>
                    <div className={mystoryStyles.storycard}>
                        <h2>
                            Australia and New Zealand
                        </h2>
                        <BackButton destination="/about/"/>
                        <NavButtons 
                            currentCardIndex={this.state.currentCardIndex}
                            showNextCard={this.showNextCard}
                            showPreviousCard={this.showPreviousCard}
                            />
                        <Img 
                            className={mystoryStyles.storyImage}
                            fluid={this.props.data.image2.childImageSharp.fluid} 
                            alt="" 
                            imgStyle={{ objectFit: 'contain' }}/>
                        <p>
                        After I graduated from my Bachelor’s, I spent the next few years working in hospitality and farm jobs around the USA, Australia and New Zealand, as well as travelling a lot in between! 

                        I also completed my Master’s of Biological and Bioprocess Engineering at the University of Sheffield. I first got into coding during this time too: I made a program to help people build their own genes for my Master’s thesis, and I designed and built an Android game when I was in New Zealand. 
                        </p>
                    </div>
                    <div className={mystoryStyles.storycardNext}></div>
                </Layout>
            </div>
          )
      } else if (this.state.currentCardIndex === 2){
        return (
            <div className={mystoryStyles.background}>
                <Layout>
                    <div className={mystoryStyles.storycard}>
                        <h2>
                            London and Amsterdam 
                        </h2>
                        <BackButton destination="/about/"/>
                        <NavButtons 
                            currentCardIndex={this.state.currentCardIndex}
                            showNextCard={this.showNextCard}
                            showPreviousCard={this.showPreviousCard}
                            />
                        <Img 
                            className={mystoryStyles.storyImage}
                            fluid={this.props.data.image3.childImageSharp.fluid} 
                            alt="" 
                            imgStyle={{ objectFit: 'contain' }}/>
                        <p>
                            When I came back to the UK, I started working as a TechOps Engineer at SpotX (an ad tech company) in London. I then came to Amsterdam in 2018, and have been loving living here ever since! I spent most of my time here working at Speakap as a Second Line Support Engineer, where I was able to work with both coding and design. 
                            Since being laid off from Speakap due to the Corona crisis, I have improved my design skills and recently finished a 3D Artist internship at videOrbit Studio.
                        </p>
                    </div>
                </Layout>
            </div>
          )
      }
    }
  }

export default MyStory;

export const storyImage = graphql`
  fragment storyImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "stonewall.png" }) {
      ...storyImage
    }

    image2: file(relativePath: { eq: "koala.png" }) {
      ...storyImage
    }

    image3: file(relativePath: { eq: "amsterdam.png" }) {
      ...storyImage
    }
  }
`
